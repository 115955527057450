import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import CompDBan from "../components/CompDBan";

import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/CompD/minibar-shop.jpg";
import pic10 from "../assets/images/compD/minibar-qr.jpg";
import pic11 from "../assets/images/compD/minibar-shoppingcart.jpg";
import pic13 from "../assets/images/compD/test-a.jpg";
import pic14 from "../assets/images/compD/test-b.jpg";

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Competency D</title>
      <meta name="description" content="Competency D" />
    </Helmet>

    <CompDBan />

    <div id="main">
      <section id="intro">
        <div className="inner">
          <header className="major">
            <h2>Introduction</h2>
          </header>
          <p>
            User experience design is an integral part of the informatics field. In order to create usable technology solutions, information professionals must first identify user needs and create prototypes to test a product’s usability. Interactive prototypes are an efficient way to test new concepts and receive early user feedback, which allows products to be iteratively improved before entering the development phase (Palliyaguru, 2018). Interactive prototypes can be created at any phase in a project and can built using both low-fidelity sketches and high-fidelity wireframes. Additionally, early stage low-fidelity prototyping is highly useful for validating new product ideas, and high-fidelity prototypes and wireframes can gather useful feedback about interactivity and usability. As a UI designer, I regularly create interactive prototypes during all stages of a product’s development. The feedback that I receive from users is invaluable and helps me create better products. Moreover, evaluating usability by testing prototypes is highly beneficial to the informatics profession as a whole, and ultimately, can help users perform their jobs more efficiently.
          </p>
        </div>
      </section>
      <section id="overview">
        <div className="inner">
          <header className="major-black">
            <h2>Coursework Overview</h2>
          </header>
          <p>
            I have had many opportunities to create low and high-fidelity interactive prototypes in my career as a UI designer and in this program’s courses. These experiences have helped me understand the importance of using prototypes to ideate informatics products and evaluate usability. INFM 204, Human Centered Design, allowed me to practice creating prototypes and evaluate user needs in different scenarios. During this course, I created a high-fidelity design and prototype for a hotel minibar mobile app. This project gave me the opportunity to practice creating interactive prototypes and solve usability problems through iterative designs. Additionally, in INFM 200, Informatics Fundamentals, I had the chance to discuss usability testing and share my professional experiences with my classmates. During this discussion, I talked about the process of A/B testing and provided examples of an A/B usability test that I performed for my job as a web developer. I also learned about my classmates’ experiences with evaluating user services in their careers, which helped me understand the importance of identifying user needs in different industries.
          </p>

          <div className="grid-wrapper">
            <div className="col-6">
              <div className="box">
                <h3>Evidence 1</h3>
                <p>
                  INFM 204 Project: Designing a mobile application for a hotel minibar.
                </p>
                <a href="#two" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="box">
                <h3>Evidence 2</h3>
                <p>
                  INFM 200 Discussion: How A/B testing can improve user experience and increase ROI.
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <img src={pic09} alt="A person using the Minibar app on an iPhone" className="image fit" />
              </div>
              <div className="col-6">
                <header className="major">
                  <h3>
                    <i class="icon fa-chart-area"></i> Evidence 1: Designing a Mobile Application for a Hotel Minibar
                  </h3>
                </header>
                <p>
                  In INFM 204, I had the opportunity to explore creating prototypes and identify user needs for a wide variety of products and services. One project for this class involved designing wireframes and an interactive prototype for a hotel minibar. The overall goal of this project was to create a usable iOS prototype that would allow a user to purchase food and drink items from a hotel’s minibar. First, I created a list of functional and usability requirements. Functional requirements specify what an application should do, while usability requirements describe the behaviors and interactions of the application (Eriksson, 2012). In order to identify functional and usability requirements, information professionals must identify user needs by talking to their client and understanding the client’s experiences. I used these requirements to influence how the prototype should be designed, and I built the prototype in Adobe XD. Once the prototype was finished, I tested it, got feedback from my classmates, and made changes as needed to improve the app’s usability. Creating this prototype helped me learn more about ideating new informatics products and prototyping new concepts based on user needs.
                </p>
                <p>
                  <a href="https://xd.adobe.com/view/067d1241-4f16-4886-a034-c9aa508bdf0c-532e/" target="_blank" className="button next">
                    Try the Prototype
                  </a>
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1LymKu2nieRV7gwdkbXcntRGXDGCyTK4K/view?usp=sharing" target="_blank" className="button next">
                    Read about the Project
                  </a>
                </p>
              </div>

              <div className="col-6">
                <div className="box">
                  <img src={pic10} alt="iPhone screen featuring the QR code function of the Minibar app" className="image fit" />
                  <p>
                    <i>Scanning a QR code on the back of the room key makes it easy to set up an account.</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic11} alt="Two iPhones showing a shopping cart for the Minibar app" className="image fit" />
                  <p>
                    <i>Users can add minibar items to the shopping cart and clearly see the price of the items before checking out. </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-12">
                <header className="major">
                  <h3>Evidence 2: How A/B Testing can Improve User Experience and Increase ROI.</h3>
                </header>
                <p>
                  An important part of evaluating a product’s usability involves testing multiple versions of a prototype to determine which version is more user friendly. One way this can be accomplished is through A/B testing, which is a type of usability test that compares multiple versions of a website or app to randomized users to determine usability. In INFM 200, I discussed using A/B testing to evaluate prototypes and examine user experience patterns. I shared my experiences creating A/B tests for a client’s website with my classmates and included detailed information about the process and outcomes of the experiment. I also discussed the importance of evaluating a prototype’s usability through comparative testing, which can ultimately result in increased lead generation and ROI for businesses. Some of my classmates who were interested in implementing A/B testing in their own workplaces asked me questions about my process, and I was able to direct them to the proper tools to create a test. I also learned about my classmates’ experiences implementing various user testing analytics in a wide variety of careers and workplace settings. After reading their experiences, I learned that user testing evaluation is not only useful for UX design but can be successfully used in professions such as information systems, banking, and marketing.
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1iWMJtWayFVsN-OOzeJVRHZfrT2JrfNe2/view?usp=sharing" target="_blank" className="button next">
                    Read about A/B Testing
                  </a>
                </p>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic13} alt="An example of a web form used in AB testing" className="image fit" />
                  <p>
                    <i>The first version of the web form before the A/B test.</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic14} alt="An example of an improved web form used in AB testing" className="image fit" />
                  <p>
                    <i>The improved and shortened web form after the A/B test.</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="conclusion">
        <div className="inner">
          <header className="major">
            <h2>Conclusion</h2>
          </header>
          <p>
            Identifying user needs and creating prototypes are important processes that can help introduce new concepts and evaluate a product’s usability. Creating interactive prototypes allow information professionals to receive feedback from users during the pre-development phase of a product’s design, which can help save costs and improve a product’s usability. In INFM 204, I created an interactive prototype that would allow a user to purchase food and drink items from a hotel’s minibar. This project helped me practice designing new concepts for industries that I was unfamiliar about. Additionally, the feedback that I received from my classmates helped me evaluate my prototype and make changes to improve usability. In INFM 200, I also had the chance to share my experience creating A/B tests for a website with my classmates and discuss the importance of user testing with them. During this discussion, I also learned about how my classmates evaluate usability in a wide variety of careers and industries. Both of these experiences helped me improve my understanding of identifying user needs, prototyping concepts, and evaluating a product’s usability.
          </p>
          <div className="box">
            <h3>References</h3>
            <p>
              Eriksson, U. (2012, April 5). Why is the difference between functional and Non-functional requirements important? ReQtest: https://reqtest.com/requirements-blog/functional-vs-non-functional-requirements/
            </p>
            <p>
              Palliyaguru, C. (2018, September 24). Basics of Prototyping. UX Planet: https://uxplanet.org/basics-of-prototyping-1a4106e12c0e
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Landing;

import React from 'react'

const CompDBan = (props) => (
    <section id="banner" className="style2 bg-image-compd">
        <div className="inner">
            <header className="major">
                <h1>Competency D</h1>
            </header>
            <div className="content">
                <p>Identify user needs, ideate informatics products and services, prototype new concepts, and evaluate a prototype’s usability</p>
            </div>
        </div>
    </section>
)

export default CompDBan
